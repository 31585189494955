<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title>Inscriptions</v-toolbar-title>

      <!-- eslint-disable max-len -->
      <v-btn
        icon
        :href="`${baseUrl}admin/misc-generate-excel.php?cursus=${cursusId}&instanceId=${instanceId}&access=${accessToken}`"
        target="_blank"
        small
        class="ml-2"
      >
        <v-icon>mdi-file-excel</v-icon>
      </v-btn>
      <!-- eslint-enable max-len -->

      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        >
          <template v-slot:label>
            <span class="font-weight-bold">Rechercher</span>
          </template>
        </v-text-field>
      </template>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="registrations"
      :loading="registrationsAreLoading"
      :search="search"
      :custom-filter="customFilter"
      show-expand
      single-expand
    >
      <template v-slot:top v-if="$vuetify.breakpoint.xsOnly">
        <div class="px-4 d-flex">
          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            hide-details
          >
            <template v-slot:label>
              <span class="font-weight-bold">Rechercher</span>
            </template>
          </v-text-field>
        </div>
      </template>

      <template v-slot:item.order_number="{ item }">
        <span class="font-weight-medium"># {{ item.order_number }}</span>
      </template>

      <template v-slot:item.last_name="{ item }">
        <div class="d-flex align-center py-2">
          <v-icon
            class="mr-2"
            large
            :color="getGenderColor(item.gender)"
          >
            mdi-account-circle
          </v-icon>

          <div>
            <div class="font-weight-medium">
              {{ item.first_name }} {{ item.last_name.toUpperCase() }}
            </div>

            <div class="text-caption">
              {{ item.other_name }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.birth_date="{ item }">
        <div class="py-2">
          <div>{{ item.birth_date | formatDate }}</div>

          <div class="caption">à {{ item.birth_place }}</div>
        </div>
      </template>

      <template v-slot:item.file="{ item }">
        <v-btn
          icon
          :href="`${baseUrl}admin/misc-get-pdf.php?type=enr&id=${item.id}&access=${accessToken}`"
          target="_blank"
        >
          <v-icon>mdi-file</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-3">
          <v-list>
            <v-row>
              <v-col>
                <v-subheader>Contact</v-subheader>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="orange">mdi-email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.email }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="light-green">mdi-phone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.mobile }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
              </v-col>

              <v-col>
                <v-subheader>Adresse</v-subheader>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="red">mdi-map-marker</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.street }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="grey">mdi-city</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.postal_code }} - {{ item.city }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="blue">mdi-earth</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.country }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
              </v-col>
            </v-row>
          </v-list>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getGenderColor } from '@/mixins/methodsMixin';

export default {
  // name: 'AdminDahuRegistrations',

  props: {
    instanceId: String,
    cursusId: String,
  },

  mixins: [getGenderColor],

  data: () => ({
    headers: [
      { text: 'ORDRE', value: 'order_number' },
      { text: 'ETUDIANT', value: 'last_name' },
      { text: 'NAISSANCE', value: 'birth_date' },
      {
        text: 'NATIONALITE',
        value: 'nationality',
        sortable: false,
        filterable: false,
      },
      {
        text: 'N° ID',
        value: 'id_number',
        sortable: false,
      },
      {
        text: 'DOSSIER',
        value: 'file',
        sortable: false,
        filterable: false,
      },
      { text: '', value: 'data-table-expand' },
    ],
    registrations: [],
    registrationsAreLoading: false,
    search: '',
  }),

  created() {
    this.getRegistrations();
  },

  computed: {
    accessToken() {
      return this.$http.defaults.headers.common.Authorization.replace('Bearer ', '');
    },

    baseUrl() {
      return process.env.VUE_APP_AXIOS_BASE_URL;
    },
  },

  methods: {
    async getRegistrations() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.registrationsAreLoading = true;
        const response = await this.$http.get(`admin/registration-enr-get.php?id_instance=${this.instanceId}&section=${this.cursusId}`);
        this.registrations = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.registrationsAreLoading = false;
      }
    },

    customFilter(value, search, item) {
      if (!value) return false;
      const lowerCaseSearch = search.toLowerCase();
      if (value === item.last_name
      && (value.toLowerCase().indexOf(lowerCaseSearch) !== -1
      || item.first_name.toLowerCase().indexOf(lowerCaseSearch) !== -1)) {
        return true;
      }
      if (value === item.birth_date) {
        return this.$options.filters.formatDate(value).indexOf(lowerCaseSearch) !== -1;
      }
      return value.toString().indexOf(lowerCaseSearch) !== -1;
    },
  },
};
</script>
