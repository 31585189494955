var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Inscriptions")]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","href":(_vm.baseUrl + "admin/misc-generate-excel.php?cursus=" + _vm.cursusId + "&instanceId=" + _vm.instanceId + "&access=" + _vm.accessToken),"target":"_blank","small":""}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1),(_vm.$vuetify.breakpoint.smAndUp)?[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("Rechercher")])]},proxy:true}],null,false,76880263),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]:_vm._e()],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.registrations,"loading":_vm.registrationsAreLoading,"search":_vm.search,"custom-filter":_vm.customFilter,"show-expand":"","single-expand":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.xsOnly)?{key:"top",fn:function(){return [_c('div',{staticClass:"px-4 d-flex"},[_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("Rechercher")])]},proxy:true}],null,false,76880263),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}:null,{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v("# "+_vm._s(item.order_number))])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":_vm.getGenderColor(item.gender)}},[_vm._v(" mdi-account-circle ")]),_c('div',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name.toUpperCase())+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.other_name)+" ")])])],1)]}},{key:"item.birth_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.birth_date)))]),_c('div',{staticClass:"caption"},[_vm._v("à "+_vm._s(item.birth_place))])])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":(_vm.baseUrl + "admin/misc-get-pdf.php?type=enr&id=" + (item.id) + "&access=" + _vm.accessToken),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":headers.length}},[_c('v-list',[_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v("Contact")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.email)+" ")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"light-green"}},[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.mobile)+" ")])],1)],1),_c('v-divider',{attrs:{"inset":""}})],1),_c('v-col',[_c('v-subheader',[_vm._v("Adresse")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-map-marker")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.street)+" ")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-city")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.postal_code)+" - "+_vm._s(item.city)+" ")])],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-earth")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.country)+" ")])],1)],1),_c('v-divider',{attrs:{"inset":""}})],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }